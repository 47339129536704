.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  line-height: 20px;
}
/* new login page styling */
.login-page-container {
  min-height: 100vh;
}
.login-form-logo {
  width: 12rem;
  height: auto;
  object-fit: contain;
}
.login-form-container {
  background-color: white;
  border-radius: 30px;
  padding: 25px !important;
  font-family: "Onest", sans-serif;
}
.login-form-body h4 {
  font-size: 2rem;
  font-family: "Onest", sans-serif;
}
.login-form-input label {
  color: gray;
  margin-left: 5px;
}
.login-form-input input,
.otp-form-container input {
  border-radius: 5px;
  padding: 15px 18px;
  background-color: #f0f3f6;
  border: none;
}
.login-form-input input:focus {
  outline: none;
}
.login-form-input input.password {
  border: none;
  background-color: #f0f3f6;
}
input.password:focus {
  box-shadow: none;
  border-right: none;
}
.input-group {
  border-radius: 5px;
  background-color: #f0f3f6;
}
.input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 14px;
}
/* Basic styling for the checkbox */
.custom-checkbox {
  appearance: none; /* Remove default styles */
  width: 15px;
  height: 15px;
  border: 2px solid #b3b7ee; /* Default border color */
  border-radius: 4px; /* Optional: round corners */
  position: relative;
  cursor: pointer;
  padding: 2px;
}
/* Styling for the checked state */
.custom-checkbox:checked {
  background-color: #5051fa;
  border-color: #5051fa;
  color: white; /* Change to your desired color */
}
/* Optional: Styling for a checkmark */
.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form-button {
  padding: 15px 25px;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}
.login-form-button.back-btn-cv {
  background: gray !important;
  font-weight: 500;
}
.welcome-audio-buttons button {
  padding: 12px 20px;
  color: white;
  border: none;
  border-radius: 12px;
  background-color: #5c7ce5;
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}
.login-form-button.disabled {
  background: gray;
}
.login-page-right {
  position: relative;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 27px;
  overflow: hidden;
}
.login-search-img {
  width: 75%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.polygon-top-left {
  width: 50px;
  height: auto;
  position: absolute;
  top: -1%;
  left: -20px;
}
.cells-top-right {
  height: 100px;
  width: auto;
  position: absolute;
  top: 1%;
  right: 3%;
}
.polygon-bottom-left {
  height: 140px;
  width: auto;
  position: absolute;
  bottom: 0%;
  left: -30px;
}
.polygon-bottom-right {
  height: 180px;
  width: auto;
  position: absolute;
  bottom: 0%;
  right: -60px;
}
.login-stats {
  position: absolute;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1280px) {
  .login-stats {
    width: 100% !important;
  }
  .login-page-container {
    min-height: 90vh !important;
    max-height: 90vh !important;
    align-self: center;
  }
  .signup-right-girl {
    height: 250px !important;
    width: auto;
    transform: translateX(-20px) !important;
  }
}
.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.password-textfield {
  background-color: #f0f3f0;
  border-radius: 5px;
}