body {
  font-family: "Open Sans", sans-serif !important;
}

nav {
  background-color: #fff !important;
  transition: all 0.3s linear;
}

#hero {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.open-nav {
  height: 220px;
  overflow: hidden;
}

.admin-email {
  /* background-color: #50B482; */
  color: black;
  border-radius: 10px;
  padding: 5px 5px;
  margin: 0;
}

.profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.nav-items-2.active {
  background: #ffffff45;
  color: white;
}

.nav-link {
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
  transform: all 0.3s linear !important;
}

/* .nav-link:hover {
  text-shadow: 0 0 #F5F5F5 !important;
  color: black;

} */

.email-in-topnav {
  display: block;
}

.email-in-sidebar {
  display: none;
}

@media screen and (max-width: 470px) {
  .email-in-sidebar {
    display: block;
  }

  .admin-email {
    color: white;
  }

  .email-in-topnav {
    display: none;
  }
}

.nav-link-home {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/* .nav-link-home:hover {
  color: var(--bs-nav-link-color);
} */

.navbar-nav .nav-item:hover .nav-link::after {
  transform: translateX(-50%) scale(1);
}

.user-name {
  color: gray !important;
  font-weight: bold;
}

.navbar {
  position: relative;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: gray;
}

.mobile-nav {
  background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)";
  position: fixed;
  width: 200px;
  right: -200px;
  top: 40px;
  bottom: 0;
  padding: 1rem !important;
  transition: all 0.3s linear;
  z-index: 10;
}

.open-mobile-nav {
  right: 0;
}

.close-icon {
  font-size: 1.5rem;
  color: black;
}

.close-btn {
  background: transparent;
  border: none;
}

.speak-test,
.predictive {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
  border-bottom-color: #f4b844;
  flex: 1;
  margin: 0 2px;
}

.user-dash-div {
  border-radius: 16px;
  background: #f5f5f5;
  border: 4px solid transparent;
  flex: 1;
  margin: 0 2px;
}

.test-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.test-section {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}

.test-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.content {
  font-size: 16px;
  font-weight: normal;
  color: #000;
  margin: 0;
}

.plan {
  text-decoration: none;
  color: #005ce7;
  font-weight: 600;
}

.cvUpload {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-top: 3rem;
}

.polygon {
  width: 250px;
  height: 250px;
  object-fit: contain;
  margin-top: 1rem;
}

.report_polygon {
  width: 360px;
  height: 360px;
  object-fit: contain;
}

.report-chart {
  max-width: 550px;
}

.level-text {
  font-size: 32px;
  padding: 0 0 0 3%;
  bottom: 140px;
}

/* .apexcharts-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.test-start {
  text-decoration: none;
  border-radius: 5px;
  background: #43addb;
  width: 130px;
  height: 32px;
  text-align: center;
  color: white;
  line-height: 2;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  display: block;
}

.test-start:hover {
  background: #399cc7;
  color: white;
}

.robot {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-top: 1rem;
}

.user-dash-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: 1.8rem;
}

.faq-work {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
}

.faq-prepare {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
}

.faq-heading {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-top: 5px !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: 15px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 16px !important;
}

.question {
  font-size: 16px;
  font-weight: bold;
  color: #a5a5a9;
  transition: color 0.5s ease-in-out;
}

.answer {
  font-size: 16px;
  font-weight: normal;
  color: #615e64;
}

.question-section {
  border-bottom: 2px solid #aeaeaf;
}

.collapse-area {
  transition: all 0.5s ease-in-out;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
}

.collapse-show {
  height: 120.59px;
}

.question-active {
  color: #000;
}

.cant-find {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.email {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}

.info-bg {
  background: #43addb;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-link {
  text-decoration: none;
  color: #fff;
}

.email-link:hover {
  text-decoration: underline;
  color: white;
}

.footer-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}

.bg-footer {
  background-color: #04d39c;
  height: 135px;
}

.social-icon {
  border: 2px solid white;
  border-radius: 50%;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  padding: 9px;
  color: white;
  cursor: pointer;
  transition: all 0.3s linear;
}

.social-icon:hover {
  background-color: #000;
  color: white;
  border-color: black;
}

.copy-right {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.navigate-up {
  position: fixed;
  z-index: 3;
  right: 4rem;
  bottom: 3rem;
  background: gray;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  animation: pop-up 2s ease infinite;
  border: none;
}

.navigate-up:hover {
  color: white;
}

.container-bar {
  width: 60%;
  margin: 0 auto;
}

.bar-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bar-progress::before {
  content: "";
  position: absolute;
  background-color: #f3f3f3;
  left: 33px;
  border-radius: 5px;
  height: 10px;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 1s ease;
  width: 90%;
  top: 50%;
}

.progres {
  position: absolute;
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  width: 50%;
  height: 10px;
  z-index: -1;
  top: 50%;
  left: 33px;
  transform: translateY(-50%);
  transition: all 1s ease;
  border-radius: 5px;
}

.right-info {
  /* width: 405px; */
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 470px;
  overflow-y: auto;
}

.s-admin-info {
  column-gap: 1rem;
}

.s-admin-logo {
  background: #e1e1e1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.admin-active-title {
  text-shadow: 0 0 BLACK;
  color: #171717;
  font-size: 1.5rem;
}

.circle-cv {
  background-color: #f5f5f5;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle {
  background-color: #fff;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #dddfe1;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle-cv.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -23px;
  top: 18px;
}

.caption {
  margin-top: 8px;
  text-align: center;
}

.caption-bar {
  font-weight: bold;
  color: #464a53;
  font-size: 16px;
  position: relative;
  top: 10px;
}

.bg-white {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
  border-radius: 30px;
  height: auto;
  width: 600px;
}

.reset-btn-modify {
  width: 200px;
  outline: none;
  border: none;
}

@keyframes pop-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 1166px) {
  .content br {
    display: none;
  }
}

@media (min-width: 470px) and (max-width: 620px) {
  .collapse-show {
    height: 135px;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .collapse-show {
    height: 170px;
  }

  .answer {
    font-size: 15px;
  }
}

@media (max-width: 1040px) {
  .test-section {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1060px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 2rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 126px;
  }
}

@media (max-width: 990px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 110px;
  }
}

@media (max-width: 900px) {
  .report_polygon {
    width: 240px;
    height: 240px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 100px;
  }
}

@media (max-width: 870px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 6rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 7%;
    bottom: 98px;
  }
}

@media (max-width: 767px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 11% 0 0;
    bottom: 136px;
  }
}

@media (max-width: 600px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 136px;
  }
}

@media (max-width: 445px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 400px) {
  .report_polygon {
    width: 230px;
    height: 230px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 390px) {
  .report_polygon {
    width: 220px;
    height: 220px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 112px;
  }
}

@media (max-width: 375px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 108px;
  }
}

@media (max-width: 350px) {
  .report_polygon {
    width: 180px;
    height: 180px;
    object-fit: contain;
    margin-top: 5rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 0 0 0%;
    bottom: 115px;
  }
}

@media (max-width: 330px) {
  .report_polygon {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 115px;
  }
}

@media (max-width: 300px) {
  .report_polygon {
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 120px;
  }
}

@media (max-width: 280px) {
  .report_polygon {
    width: 110px;
    height: 110px;
    object-fit: contain;
    margin-top: 7rem;
  }

  .level-text {
    font-size: 15px;
    padding: 0 5% 0 0;
    bottom: 107px;
  }
}

@media (max-width: 410px) {
  .cvUpload {
    height: 170px;
    width: 170px;
  }

  .robot {
    height: 200px;
    width: 200px;
  }

  .polygon {
    height: 250px;
    width: 250px;
  }

  .content {
    font-size: 14px;
  }
}

@media (max-width: 417px) {
  .question {
    font-size: 16px;
  }
}

@media (min-width: 560px) and (max-width: 725px) {
  .cant-find {
    font-size: 34px;
  }
}

@media (min-width: 470px) and (max-width: 559px) {
  .cant-find {
    font-size: 28px;
  }

  .email {
    font-size: 16px;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .cant-find {
    font-size: 22px;
  }

  .email {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .footer-grid {
    grid-template-columns: 1fr !important;
  }

  .offer {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }

  .foot-contact {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
}

@media (max-width: 600px) {
  .navigate-up {
    height: 35px;
    width: 35px;
    right: 3rem;
    bottom: 2rem;
  }

  .social-icon {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 334px) {
  .cvUpload {
    height: 150px;
    width: 150px;
  }

  .polygon {
    height: 200px;
    width: 200px;
  }

  .robot {
    height: 150px;
    width: 150px;
  }

  .collapse-show {
    height: 190px;
  }

  .navigate-up {
    height: 35px;
    width: 35px;
    right: 2rem;
    bottom: 2rem;
  }

  .cant-find {
    font-size: 18px;
  }

  .email {
    font-size: 13px;
  }
}

@media (max-width: 414px) {
  .mobile-nav {
    width: 100%;
    right: -420px;
  }

  .open-mobile-nav {
    right: 0px;
  }

  .copy-write {
    font-size: 14px;
  }
}

@media (min-width: 1041px) and (max-width: 1316px) {
  .collapse-show {
    height: 150px;
  }
}

@media (min-width: 945px) and (max-width: 1100px) {
  .container-bar {
    width: 70%;
  }
}

@media (min-width: 850px) and (max-width: 944px) {
  .container-bar {
    width: 80%;
  }
}

@media (min-width: 450px) and (max-width: 849px) {
  .container-bar {
    width: 80%;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }
}

@media (max-width: 580px) {
  .caption-bar {
    font-size: 14px;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }

  .circle-container {
    left: -5px;
  }
}

@media (max-width: 490px) {
  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 14px;
  }

  .circle {
    height: 50px;
    width: 50px;
    font-size: 14px;
  }
}

@media (max-width: 449px) {
  .container-bar {
    width: 100%;
  }
}

@media (max-width: 280px) {
  .container-bar {
    width: 100%;
  }

  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 12px;
  }

  .circle {
    height: 45px;
    width: 45px;
    font-size: 14px;
  }
}

.download-icon-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.back-arrow-icon {
  margin-left: 20px;
  cursor: pointer;
}

.download-icon {
  cursor: pointer;
  width: 110px;
}

.close-div {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 1.7rem;
  display: none !important;
}

.sidebar {
  width: 300px;
  transition: all 0.3s linear;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  /* height: 1200px; */
}

.sidebar-collapse {
  width: 0;
  overflow: hidden;
}

.open-sidebar {
  width: 300px !important;
  overflow: visible !important;
}

.side-title {
  font-size: 20px;
  margin-top: 3.25rem;
  color: #5f00d9;
}

.side-content {
  height: 100vh !important;
}

.nav-items-2 {
  transition: all 0.3s linear;
  cursor: pointer;
  color: white;
  font-weight: 400;
  border-radius: 10px;
  gap: 1rem;
  white-space: nowrap;
}

/* 
.nav-items-2:hover {
  background: white;
  color: black;
} */

.top-nav {
  padding: 1rem 1rem 1rem 2rem;
  background: #eeeef4;
}

.left-super-info {
  width: 100%;
}

.btn-user {
  font-size: 2rem !important;
}

.btn-toggle {
  font-size: 2rem !important;
}

.background {
  background-color: #eeeef4;
  padding: 24px 0;
  height: 100%;
}

.dashboard-top {
  margin: 0rem 3rem;
  padding: 0rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.s-dashboard-top {
  margin: 0rem 2rem;
  padding: 0rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.total {
  background: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;
}

.caption {
  font-size: 13px;
  font-weight: 500;
  color: #535d66;
  margin-bottom: 8px;
  text-shadow: 0 0 #535d66;
  text-align: left;
}

.caption-statistics {
  font-size: 24px;
  font-weight: 500;
  color: #101828;
  margin-bottom: 8px;
  text-shadow: 0 0 #535d66;
  text-align: left;
}

.content {
  font-size: 24px;
  font-weight: 500;
  color: #171717;
}

.valid-user {
  font-size: 2rem;
}

.tag {
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
  position: relative;
  top: -4px;
  letter-spacing: 1px;
}

.balance {
  gap: 9rem;
}

.btn-deposit,
.btn-withdraw {
  height: 40px;
  width: 130px;
  border-radius: 10px;
  background-color: #50b482;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 500;
  text-shadow: 0 0 white;
  transition: all 0.3s linear;
  outline: 0;
  border: 0;
}

.user-info {
  margin-left: 15px;
}

.user-progress-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

@media (max-width: 550px) {
  .user-progress-container {
    display: block;
  }
}

@media (max-width: 350px) {
  .user-progress-container {
    width: 200px;
  }
}

.btn-deposit:focus,
.btn-withdraw:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.transactions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 3rem;
  gap: 1rem;
}

.s-transactions {
  margin: 2rem 2rem;
  gap: 2rem;
}

.price-side {
  background-color: #ffffff;
  border-radius: 22px;
  padding: 1.2rem;
  position: relative;
  overflow-x: auto;
}

.trans-side {
  background-color: #ffffff;
  border-radius: 22px;
  padding: 1.2rem;
}

.profit {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.tab {
  border: none;
  height: 25px;
  width: 30px;
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #797e82;
  font-weight: 500;
  font-size: 13px;
}

.tab:focus {
  background-color: white;
}

.tab-active {
  background-color: white;
}

.tab:hover {
  background-color: white;
}

.date {
  font-size: 12px;
  color: #797e82;
  margin-bottom: 0;
  margin-top: 1rem;
}

.img-area {
  position: relative;
}

.graph {
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
}

.line {
  width: 100%;
  object-fit: cover;
}

.dollar {
  height: 40px;
  width: 40px;
  background: #f3f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.1rem;
  text-shadow: 0 0 #171717;
  font-weight: 500;
  /*style 1 for border and background of chararcter round icon*/
  background: #50b482;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  mask-composite: exclude;
  color: white;

  /* style 2 for border and background of chararcter round icon*/
  /* border: double 2px transparent;
  border-radius: 80px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, rgb(69, 99, 209),#b762d3);
  background-origin: border-box;
  background-clip: padding-box, border-box; */
}

.date-time {
  font-size: 14px;
  font-weight: 400;
  color: #797e82;
}

.trans-price {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.btn-block {
  width: 100%;
  background-color: #50b482 !important;
  border-radius: 10px !important;
  color: white !important;
  font-weight: 500 !important;
  height: 50px !important;
}

.increment {
  font-size: 14px;
  color: #797e82;
  font-weight: 400;
}

.dashboard-footer {
  margin: 0 3rem;
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 22px;
}

.loan-tag {
  background: #5f00d9;
  color: white;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.dashfoot-content {
  font-size: 18px;
  font-weight: 500;
  color: #171717;
  padding-right: 5rem;
  margin-top: 1rem;
}

.pi-taken {
  border: none;
  width: 80px;
  font-size: small;
  border-radius: 5px;
  background-color: #50b482;
  color: white;
  padding: 8px;
}

.pi-not-taken {
  border: none;
  width: 80px;
  font-size: small;
  border-radius: 5px;
  background-color: rgb(255, 91, 55);
  color: white;
  padding: 8px;
}

.contact-content {
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding-right: 5rem;
  margin-top: 1rem;
}

.contact-tag {
  background: white;
  color: black;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.export-area {
  padding: 0 10rem;
}

.table-width {
  width: 85%;
  margin: 1rem auto;
  background: white;
  border-radius: 10px;
}

.thead-border {
  border-bottom: 1px solid lightgray;
  padding: 1rem !important;
}

.thead-tabs {
  gap: 1rem 1rem;
}

.table-tab {
  width: 105px;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.table-tab:hover {
  border-bottom: 2px solid blue;
  font-weight: bold;
}

.search-field {
  position: relative;
}

.search-input {
  width: 230px;
  padding-left: 1.5rem;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  font-size: 14px;
}

.search-input:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.2rem;
  position: absolute;
  background-color: transparent;
  border: none;
  left: -5px;
  top: 2px;
  cursor: pointer;
}

.search-icon:hover {
  font-weight: bold;
}

.category {
  font-size: 12px;
  font-weight: 500;
  color: #797e82;
}

.table-tag {
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
}

.table-border {
  border-bottom: 1px solid lightgray !important;
}

.table-content {
  padding: 0 2rem;
}

.table-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #797e82;
  text-shadow: #797e82;
  margin-bottom: 0;
}

.thead-content {
  color: #797e82;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
}

.search-area {
  position: relative;
  text-align: right;
}

.search-field {
  border: none;
  height: 35px;
  font-size: 1.1rem;
}

.search-field:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
}

.search-btn {
  background: transparent;
  border: none;
}

.animation-loader-width {
  padding: 0 !important;
  width: 65% !important;
}

.admin-name {
  font-weight: bold;
  position: relative;
  top: 2px;
  right: 2px;
}

.btn-user:focus {
  box-shadow: none !important;
}

.btn-toggle:focus {
  box-shadow: none !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.graph-caption {
  font-weight: 500;
  text-shadow: 0 0 black;
}

.nav-btn {
  text-decoration: none;
  color: black;
  transition: color 0.3s linear;
  font-size: 1.2rem;
}

.nav-btn:hover {
  color: #43addb;
}

@media (min-width: 1400px) {
  .btn-toggle {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .btn-toggle {
    display: none !important;
    /* Hide menu icon */
  }
}

@media (max-width: 1024px) {
  .btn-toggle {
    display: block !important;
    /* Show the menu icon */
    font-size: 1.5rem !important;
  }

  .sidebar {
    width: 0;
    /* Hide the sidebar */
    overflow: hidden;
    position: fixed;
    /* Fixed position */
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #f5f5f5;
    /* Adjust background color */
    z-index: 5;
    /* Ensure it's above other content */
    transition: width 0.3s ease;
    /* Smooth transition */
  }

  .open-sidebar {
    width: 300px !important;
    /* Sidebar width when open */
    overflow: visible !important;
  }
}

@media (max-width: 1399px) {
  .btn-user {
    display: none !important;
  }

  .cv-title {
    font-size: 11px;
  }

  .top-nav {
    padding: 1rem 2rem;
  }

  /* .sidebar {
    width: 0;
    overflow: hidden;
  } */

  .dashboard-top {
    margin: 0rem 1rem;
  }

  .balance {
    gap: 3rem;
  }

  .transactions {
    margin: 2rem 1rem;
  }

  .dashboard-footer {
    margin: 0 1rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1650px) and (max-width: 1880px) {
  .balance {
    gap: 5rem;
  }

  .dashboard-top {
    margin: 0 6rem;
  }

  .transactions {
    margin: 2rem 6rem;
  }

  .dashboard-footer {
    margin: 0 6rem;
  }
}

@media (min-width: 1400px) and (max-width: 1649px) {
  .balance {
    gap: 2rem;
  }

  .dashboard-top {
    margin: 0 2rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
  }
}

/* For laptop screens */
@media (min-width: 1025px) and (max-width: 1366px) {
  .item-icon {
    width: 80px;
    height: 80px;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .item-icon {
    width: 90px;
    height: 90px;
    opacity: 1.5;
  }
}

@media (max-width: 1150px) {
  .transactions {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 770px) {
  .sidebar {
    position: fixed;
    background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)";
    top: 0;
    bottom: 0;
    z-index: 5;
    left: 0;
  }
}

@media (min-width: 450px) and (max-width: 1050px) {
  .dashboard-top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 449px) {
  .dashboard-top {
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem 0.5rem;
  }

  .transactions {
    margin: 0rem 0.5rem;
  }

  .dashboard-footer {
    margin: 0rem 0.5rem;
  }
}

@media (max-width: 450px) {
  .search-area {
    text-align: left;
  }

  .top-nav {
    padding: 1rem 1.2rem 0.5rem 1.2rem;
  }

  .price-side {
    padding: 1.2rem 0.5rem;
  }
}

@media (max-width: 280px) {
  .search-field {
    width: 200px;
  }
}

@media (max-width: 300px) {
  .close-div {
    display: block !important;
    position: absolute;
    right: 35px;
    top: 5px;
  }
}

.cv-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.cv-select-container {
  display: flex;
  text-align: center;
  justify-content: center;
  border: dotted;
  flex-direction: column;
  max-width: 120vh;
  height: 30vh;
  margin: auto;
  cursor: pointer;
  background-color: #f5f5f5;
}

.cv-select-container p {
  text-align: center;
  font-size: 16px;
}

.responsive-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .responsive-img {
    max-width: 50%;
  }
}

.cv-info-container {
  margin: 100px auto;
}

.cv-form-btn {
  float: right;
}

.form-fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.form-fade-enter-active {
  opacity: 1;
}

.form-fade-exit {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.form-fade-exit-active {
  opacity: 0;
}

.cv-dropdown {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.dropdown-button-cv {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu-cv {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-item-cv {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-item-cv:hover {
  background-color: #f0f0f0;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #f9f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease-in-out;
  width: 250px;
}

.search-container.expanded {
  width: 300px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  background-color: #f9f9fa;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.search-button i {
  color: #333;
}

.PI-container {
  padding: 7% 10%;
}

.pi-top-main {
  text-align: center !important;
}

.PI-Home-Input {
  flex: 0 0 calc(33.33% - 1rem);
  margin-bottom: 1rem;
}

.PI-checkbox {
  padding: 0 5% 0 5%;
}

.interview-home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.interview-home-card {
  width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 15px;
}

.interview-qs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.interview-warmup-card {
  width: 100%;
  border-radius: 15px;
}

.interivew-screen-button {
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-image {
  max-width: 40%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.interview-qs-audio-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.interview-qs-audio-buttons-small {
  margin-top: 10px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-audio-buttons-large {
  margin-top: 12px;
  font-size: 14px;
  padding: 10px 10px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.project-button:hover {
  background-color: #2bafdb;
  color: floralwhite;
}

.logout-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
  width: 100px;
}

.verify-account-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
}

.faq-header {
  background-color: lightgray;
}

.interview-home-fields {
  display: flex !important;
  flex-direction: row !important;
}

.second-text-field {
  margin-left: 20px !important;
}

@media (max-width: 768px) {
  .interview-home-fields {
    flex-direction: column !important;
  }

  .second-text-field {
    margin-left: 0px !important;
  }
}

.admin-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("./assets/adminbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
  border-radius: 30px;
  width: 100% !important;
}

.verify-title {
  font-weight: 600;
  margin-top: 70px;
  font-size: 24px;
  text-align: center;
}

.verify_otp_btn {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.customBtn {
  border-radius: 25px;
  padding: 12px 20px !important;
}

.otp-timer {
  text-align: right;
  margin-right: 50px !important;
}

.verify-form {
  padding: 0 50px;
}

.span-level {
  font-size: 32px;
}

@media (max-width: 455px) {
  .span-level {
    font-size: 22px;
  }
}

@media (max-width: 375px) {
  .span-level {
  }
}

.verify-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-left: 15px;
  border: 2px solid black;
}

.admin-reset-form {
  padding: 0 20%;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #6e7881;
  border-radius: 20px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none !important;
  border-radius: 20px !important;
  background: initial;
  background-color: #5cb3cf !important;
  color: #fff;
  font-size: 1em;
}

.analytic-chart {
  background-color: #f5f5f5 !important;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
}

.table-responsive {
  /* height: 183px; */
  overflow-y: hidden;
}

.active-pie-gragh {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 10px;
}

.admin-overview {
  color: #171717;
  text-shadow: 0 0 #171717;
  column-gap: 1rem;
}

.admin-bullet {
  height: 12px;
  width: 12px;
  background: #0084ff;
  display: block;
  border-radius: 50%;
}

.organ-bullet {
  height: 12px;
  width: 12px;
  background: #1ab7ea;
  display: block;
  border-radius: 50%;
}

.over-credientials {
  column-gap: 2rem;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 15px !important;
}

.reset-password-top {
  padding: 20px;
}

.create-account {
  color: #000;
  font-size: 30px;
  font-style: normal;
  line-height: 183.2%;
}

.signup-p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.reset-btn {
  width: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #5cb3cf;
}

.reset-text {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cv-box-margin {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .reset-btn {
    width: 100%;
  }
}

.email-validation {
  margin-top: 10px;
  margin-bottom: -20px;
  color: #ea4335;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

.resend-email-top {
  margin: auto;
  justify-content: center;
  display: flex;
}

.resend-email-second {
  margin: 50px 0 0 0;
  text-align: center;
}

.resend-email-second .email-first {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.desc-email-grey {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.desc-email-sample {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.didnt-recieve-email {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 183.2%;
  margin-top: 50px;
}

.resend-email-btn {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 60%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #66b855;
}

.cv-box {
  width: 414px;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 1rem;
}

.analytics-space {
  column-gap: 2rem;
  display: flex;
}

.active-status {
  background: #2cc92c;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.inactive-status {
  background-color: rgb(255, 193, 7);
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.analytics-graph {
  width: 100%;
  border-radius: 10px !important;
}

.cv-icon {
  font-size: 3.2rem;
  color: rebeccapurple;
  margin-bottom: 4.2rem;
}

.calender-space {
  column-gap: 0.5rem;
}

.calender-icon {
  font-size: 1.5rem;
}

.cv-title {
  font-weight: 400;
  font-size: 16px;
  color: #101828;
  margin-bottom: 6px;
}

.bg-organization {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 10px;
}

.cv-number {
  font-weight: 500;
  color: #171717;
  font-size: 2rem;
}

.btn-analytics {
  background: #08a0d1 !important;
  color: white !important;
}

.navigation-name {
  color: #597c93;
  font-size: 1.2rem;
  font-weight: bold;
}

.navigation-link-icon {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  background: #0084ff;
  border-radius: 0 15px 15px 0;
}

.navigation-link {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  position: relative;
  top: -1px;
  font-weight: bold;
  text-shadow: 0 0 white;
}

@media (max-width: 768px) {
  .resend-email-btn,
  .error-btn {
    width: 100%;
  }
}

.resend-email-text {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.password-match {
  color: #66b855;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

.footer-super-admin {
  background-color: transparent;
  padding: 1rem 1rem;
  border-radius: 10px;
  height: 272px;
}

.navigation-bar-side {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.navigation-bar {
  background: #f5f5f5;
  padding: 0 0 0 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-navigation-icon {
  background: #e1e1e1;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
}

.footer-navigation {
  display: grid;
  grid-template-columns: 1fr 500px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  background-color: #e4e5e5;
}

@media (min-width: 1401px) and (max-width: 1700px) {
  .cv-box {
    width: 250px;
  }

  .cv-icon {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1605px) {
  .s-transactions {
    flex-direction: column !important;
  }

  .right-info {
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .analytics-space {
    flex-direction: column;
  }

  .left-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
  }

  .cv-box {
    width: 100%;
  }

  .cv-icon {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1250px) {
  .s-dashboard-top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1400px) {
  .animation-loader-width {
    width: 50% !important;
  }

  .footer-navigation {
    grid-template-columns: repeat(1, 1fr);
  }

  .s-transactions {
    row-gap: 35rem;
  }
}

@media (max-width: 1100px) {
  .s-transactions {
    row-gap: 25rem;
  }
}

@media (max-width: 770px) {
  .animation-loader-width {
    width: 65% !important;
  }
}

@media (max-width: 550px) {
  .s-transactions {
    row-gap: 15rem;
  }
}

@media (max-width: 620px) {
  .left-stats {
    grid-template-columns: 1fr 1fr;
  }

  .cv-box-margin:nth-child(3) {
    grid-column: 1 / span 2;
  }

  .s-dashboard-top {
    margin: 0rem 0.7rem;
  }

  .s-transactions {
    margin: 2rem 0.7rem;
  }

  .footer-super-admin {
    padding: 1rem 0rem;
  }
}

@media (max-width: 450px) {
  .s-dashboard-top,
  .left-stats {
    grid-template-columns: 1fr;
  }

  .cv-box-margin:nth-child(3) {
    grid-column: 1 / span 1 !important;
  }
}

@media (min-width: 1606px) {
  .side-content {
    height: 92%;
  }
}

@media (min-width: 1400px) and (max-width: 1605px) {
  .side-content {
    height: 95%;
  }
}

@media (min-width: 771px) and (max-width: 1399px) {
  .side-content {
    height: 96%;
  }
}

@media (max-width: 770px) {
  .close-div {
    display: block !important;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 1.5rem;
  }
}

/* @media (max-width:1380px) {
  .css-gekkuz-MuiPaper-root 
  {
    max-width: 160%;
  }
} */

.css-levciy-MuiTablePagination-displayedRows {
  display: none;
}

/* 
.page-break {
  page-break-after: always;
  break-after: page;
  margin-top: 15%;
}

.page-break1 {
  page-break-after: always;
  break-after: page;
  margin-top: 15%;
}
 */

/* Style for the scrollbar */
.table-responsive::-webkit-scrollbar {
  width: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 30px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #08a0d1;
  border-radius: 30px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* CSS for theSearch field in Reports */
.search-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease-in-out;
  width: 300px;
}

.search-container.expanded {
  width: 300px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  background-color: #ffffff;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.search-button i {
  color: #333;
}

/* @media print {
  .page-content {
    padding-top: 1cm;
    padding-bottom: 1cm;
    page-break-after: always;
  }
} */

@media print {
  /* body * {
    visibility: hidden;
  }

  .print-only,
  .print-only * {
    visibility: visible;
  } */
  .download-icon-container.oral-report {
    visibility: hidden;
  }
  ,
  .print-only {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .page-content {
    page-break-inside: avoid; /* Prevents page breaks inside the element */
    overflow: hidden; /* Prevents overflow issues */
  }
  .print-only {
    break-after: always;
  }
  .print-voc {
    break-before: always;
  }
  .top-nav{
    visibility:hidden;
  }
}
/* @media print {
  .avoid-page-break {
    page-break-inside: avoid;
  }
}
@media print {
  .page-break-before {
    break-before: always; 
  }

  .page-break-after {
    break-after: always;
  }
} */

/* In a separate CSS file */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

.css-y8ay40-MuiTableCell-root {
  font-weight: 600 !important;
}

.org-btn {
  background-color: #08a0d1;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 20px;
}

.reactour__popover {
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 14px 0.5em 3em !important;
  border: 4px solid transparent;
  border-bottom-color: #04d39c;
}

.reactour__popover span {
  background-color: #2bafdb !important;
}

.reactour__close-button {
  width: 12px !important;
  height: 12px !important;
  color: lightgray;
}

.pi-top-img {
  object-fit: cover;
  width: 100%;
}

.pi-card {
  border-radius: 16px;
  /* padding: 60px 100px; */
  background-color: white;
  border: 2px solid lightgrey;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.pi-team-dynamics-heading {
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-top: 20px;
}

.pi-detailed-container {
  height: 30vh;
}

/* .pi-section-container {
  padding: 10px 60px;
} */

/* @media (max-width: 1600px) {
  .pi-section-container {
    padding: 10px 60px;
  }
} */

.pi-card-title {
  font-size: 28px;
  font-weight: 600;
}

.pi-card-text {
  color: #787878;
  font-size: 18px;
  width: 70%;
}

.progres-labels {
  font-size: 30px;
}

.pi-img-detailed {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.pi-img-detailed-1 {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.pi-manag-container {
  margin-top: 10px;
}

.pi-img-managment {
  margin-top: -100px;
  width: 25%;
}

.pi-details-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.bar-pi-details {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}

.circular-text {
  transform: scaleX(-1);
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -90px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.pi-title {
  font-size: 45px;
  font-weight: 700;
  margin-top: -90px;
  text-align: center;
}

.dynamic-img {
  width: 250px;
}

.pi-manag-title {
  font-size: 30px !important;
}

.pi-img-summary {
  margin-left: 20%;
  width: 340px;
  justify-content: center;
}

.pi-img-org {
  width: 340px;
}

@media (max-width: 1500px) {
  .dynamic-img {
    width: 280px;
  }

  .pi-card-text {
    width: 120%;
  }

  .pi-personal-col {
    width: 100% !important;
  }

  .pi-img-managment {
    margin-top: -60px;
    width: 35%;
  }

  .pi-img-summary {
    margin-left: 20% !important;
    padding-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .pi-manag-col {
    width: 100% !important;
  }

  .pi-manag-title {
    font-size: 24px !important;
  }

  .pi-manag-text {
    font-size: 14px;
  }

  .pi-manag-row {
    flex-direction: column;
  }

  .pi-img-managment {
    margin-top: -60px;
    width: 15%;
  }

  .pi-manag-card {
    margin-top: 10%;
  }
}

@media (max-width: 1000px) {
  .pi-card {
    padding: 10px !important;
  }

  .pi-card-text {
    padding: 0px 10px;
  }

  .pi-col-5,
  .pi-col-7,
  .pi-card-text {
    width: 100%;
  }

  .pi-card-title {
    font-size: 30px;
  }

  .pi-col-5,
  .pi-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dynamic-img {
    margin-top: 5%;
    width: 25%;
  }

  .pi-dynamic-row {
    flex-direction: column !important;
  }

  .pi-img-summary {
    margin-left: 0% !important;
    width: 260px !important;
  }

  .pi-img-org {
    width: 240px;
  }

  .pi-skills-row,
  .pi-skills-padding {
    padding: 12px !important;
  }
}

@media (max-width: 950px) {
  /* .pi-section-container {
    padding: 20px !important;
  } */

  .pi-team-dynamics-heading {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .pi-manag1-card {
    margin-top: 5%;
  }
}

@media (max-width: 620px) {
  .pi-img-managment {
    width: 20%;
  }

  .pi-manag-title {
    font-size: 22px !important;
  }

  .pi-personality {
    flex-direction: column !important;
  }
}

@media (max-width: 480px) {
  .pi-img-managment {
    width: 30%;
  }

  .pi-manag-container {
    margin-top: -20px;
  }
}

.pi-skills-row {
  flex-direction: row !important;
  padding: 0px 20px !important;
}

.pi-skills-padding {
  padding: 0px 40px !important;
}

@media (max-width: 575px) {
  .dynamic-img {
    width: 200px;
  }

  .pi-card-text {
    width: 100%;
  }

  .pi-personal-col,
  .pi-col-7,
  .pi-col-5 {
    width: 100% !important;
  }

  .pi-skills-row {
    flex-direction: column !important;
  }

  .row-personal-dev {
    flex-direction: column;
  }

  .pi-img-managment {
    margin-top: -10%;
  }
}

/* .pi-detailed-card:hover {
  background-color: #000;
  color: red;
} */

.pi-detailed-card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.pi-detailed-card.flipped {
  transform: rotateY(180deg);
}

.back-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back-side {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__side {
  height: 52rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side--front {
  background-color: #fff;
}

.card__side--front-1 {
  background: linear-gradient(-45deg, #f403d1, #64b5f6);
}

.card__side--front-2 {
  background: linear-gradient(-45deg, #f321d7, #ffec61);
}

.card__side--front-3 {
  background: linear-gradient(-45deg, #24ff72, #9a4eff);
}

.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card__side--back-1 {
  background: linear-gradient(-45deg, #64b5f6, #f403d1);
}

.card__side--back-2 {
  background: linear-gradient(-45deg, #ffec61, #f321d7);
}

.card__side--back-3 {
  background: linear-gradient(-45deg, #9a4eff, #24ff72);
}

.card:hover .card__side--front-1,
.card:hover .card__side--front-2,
.card:hover .card__side--front-3 {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.card__title {
  height: 20rem;
  padding: 4rem 2rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card__title--1 .fas {
  font-size: 5rem;
}

.card__title--2 .fas {
  font-size: 5rem;
}

.card__title--3 .fas {
  font-size: 5rem;
}

.card__heading {
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  width: 75%;
}

.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.card__details {
  padding: 0 2rem 2rem;
}

.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

/* Styles for the scrollbar */
.bar-pi-details::-webkit-scrollbar {
  width: 8px;
  border-radius: 30px;
}

.bar-pi-details::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.bar-pi-details::-webkit-scrollbar-thumb {
  background-color: #2bafdb;
  border-radius: 30px;
  border: 2px solid transparent;
}

/* Firefox scrollbar styles */
.bar-pi-details {
  scrollbar-color: #2bafdb #f1f1f1;
  scrollbar-width: thin;
}

.pi-small-img {
  width: 150px;
}

.pi-grid-work-img {
  width: 140px;
}

.pi-grid-work-img.dd {
  width: 170px;
}

.pi-large-img {
  width: 40%;
}

.back-side-card-body {
  margin-top: -100px;
}

.trophy {
  width: 35px;
}

.pi-personal-img {
  width: 160px !important;
}

.pi-grid-section0 {
  color: #7c7c7c;
  font-size: 28px;
  display: flex;
  font-weight: 700;
}

.pi-last-card {
  padding: 20px;
}

@media (max-width: 1700px) {
  .label-grid {
    width: 30% !important;
  }
}

@media (max-width: 1500px) {
  .label-grid {
    width: 40% !important;
  }
}

@media (max-width: 1176px) {
  .label-grid {
    width: 50% !important;
  }
}

@media (max-width: 994px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 1000px) {
  .label-grid {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .pi-detailed-card {
    height: 20vh;
  }

  .pi-img-summary {
    margin-left: 0px;
  }

  .back-side-card-body {
    margin-top: 0px;
  }

  .pi-detail-container {
    margin-top: -50px !important;
  }

  .pi-human {
    width: 15%;
  }

  .pi-dynamic-small-row {
    text-align: center;
    justify-content: center;
    margin: auto;
    padding-bottom: 30px;
  }

  .pi-animation {
    text-align: center;
    justify-content: center;
    margin: auto;
  }

  .pi-grid-section0 {
    display: block !important;
  }

  .trophy {
    width: 30px;
  }

  .pi-last-card {
    padding: 12px;
  }
}

/* @media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
} */

@media (max-width: 873px) {
  .label-grid {
    width: 70% !important;
  }
}

/* @media (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card {
    margin-top: 12px !important;
  }

} */

@media (max-width: 787px) {
  .label-grid {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  .pi-prsonal-card {
    margin-top: 5%;
  }

  .report-tabs {
    font-size: 12px !important;
  }
}

@media (max-width: 722px) {
  .label-grid {
    width: 90% !important;
  }
}

@media (max-width: 671px) {
  .label-grid {
    width: 100% !important;
  }
}

@media (max-width: 631px) {
  .label-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 40% !important;
  }
}

@media (max-width: 537px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 447px) {
  .label-grid {
    width: 70% !important;
  }
}

@media (max-width: 570px) {
  .pi-title {
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .pi-detailed-card {
    margin-top: 5%;
  }

  .pi-personal-img {
    width: 140px !important;
  }
}

.pronunciation-img {
  width: 50px;
}

.oral-pronunciation-qs {
  display: flex;
  color: #4d4d4d;
  justify-content: space-between;
}

.pronunciation-speaking-titles {
  font-weight: 700;
  font-size: 18px;
}

.pronunciation-speaking {
  font-size: 14px;
  color: #787878;
}

.pronunciation-speaking-details {
  font-weight: 700;
  color: #4d4d4d;
}

/* 
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 22px;
  padding: 10px;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  border-radius: 16px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  margin: auto;
  border-radius: 16px;
}

.flip-card-front {
  background-color: #F9F7F7B5;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  text-align: center;
}

.flip-card-back-dimensions {
  margin: 20px auto;
  height: 120px;
  overflow: auto;
}

.flip-card-back-dimensions::-webkit-scrollbar {
  width: 10px;
}

.flip-card-back-dimensions::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.flip-card-back-dimensions::-webkit-scrollbar-thumb {
  background: #00A9FF;
  border-radius: 10px;
}

.flip-card-back-dimensions::-webkit-scrollbar-thumb:hover {
  background: #2980b9;
}


@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
} */

.pi-section-container {
  padding: 10px 160px;
}

@media (max-width: 1600px) {
  .pi-section-container {
    padding: 10px 60px;
  }
}

@media (max-width: 950px) {
  .pi-section-container {
    padding: 20px !important;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 22px;
  padding: 10px;
}

@media (max-width: 1360px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card {
    margin-top: 12px !important;
  }
}

.flip-card {
  background-color: white;
  perspective: 1000px;
  border-radius: 16px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 330px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

@media (min-width: 1360px) and (max-width: 1700px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  margin: auto;
  border-radius: 16px;
}

.flip-card-front {
  background-color: transparent;
  color: black;
  /* border: 2px solid lightgrey; */
}

.flip-card-back {
  background-color: #56367f;
  color: white;
  transform: rotateY(180deg);
  text-align: center;
}

.flip-card-back-dimensions {
  margin: 20px auto;
  height: auto;
  overflow: auto;
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.report-tabs {
  font-weight: 600;
  font-size: 1.1rem;
  transition: font-size 0.3s ease-in-out;
}

.label-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  justify-content: start;
  align-items: center;
  width: 25%;
}

.grid-item {
  padding: 9px;
  color: white;
  border-radius: 20px;
  text-align: center;
  width: auto;
}

.proun-img {
  width: 40px;
}

.proun-img-mic {
  width: 20px;
}

.grid-container-voc {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 15px 0;
  text-align: center;
}

.grid-item-voc {
  border-radius: 25px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .grid-container-voc {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .grid-container-voc {
    grid-template-columns: repeat(1, 1fr);
  }
}

.voc-titles {
  font-weight: bold;
}

/* Guage meter */
.gauge-meter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gauge-background {
  fill: none;
  stroke: #eee;
  stroke-width: 20;
}

.oral-container {
  padding: 12px 24px !important;
}

.page-content {
  padding: 12px 20px !important;
}

.gauge-section {
  fill: none;
  stroke-width: 20;
}

.green-section {
  stroke: #4caf50;
}

/* Bright green */
.blue-section {
  stroke: #2196f3;
}

/* Bright blue */
.yellow-section {
  stroke: #ffc107;
}

/* Amber */
.red-section {
  stroke: #f44336;
}

/* Red */
.pink-section {
  stroke: #e91e63;
}

/* Pink */

.needle {
  stroke: #333;
  /* Dark grey for the needle */
  stroke-width: 3;
}

.needle-base {
  fill: #333;
  /* Base circle */
}

.apex-chart-guage {
  height: 400px !important;
  width: 400px !important;
}

.user-progress-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user-details {
  display: flex;
  align-items: center;
}

.caption {
  margin-bottom: 10px;
}

.date-time {
  margin: 0;
}

.trans-price {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .user-info {
    margin-left: 10px;
  }

  .user-progress-grid {
    flex-direction: column;
    align-items: flex-start;
  }

  .trans-price {
    margin-top: 10px;
    align-self: stretch;
    margin-left: 150px;
  }

  .trans-price > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.css-rmmij8,
.css-8nphli .MuiTablePagination-actions {
  margin-top: -12px !important;
}

.css-levciy-MuiTablePagination-displayedRows,
.css-1chpzqh {
  display: block;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.common-icon {
  width: 24px;
  /* Adjust size as needed */
  height: 24px;
  margin-right: 10px;
  /* Adjust spacing as needed */
  margin-top: -20px;
}

.item-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* Adjust for desired spacing */
  opacity: 0.5;
  /* Optional: Adjust transparency */
}

.logout-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.logout-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  text-align: center;
}

.logout-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.logout-modal-close:hover,
.logout-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.logout-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.logout-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.logout-button {
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  color: white;
}

.cancel-button {
  background-color: #808080;
  color: white;
}

.reactour__popover {
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 14px 0.5em 3em !important;
  border: 4px solid transparent;
  border-bottom-color: #56367f;
}

.reactour__dot {
  background-color: #50b482 !important;
  /* Change this to your desired color */
}
.reactour__dot--active {
  background-color: #50b482 !important;
  /* Change this to your desired active color */
}

.reactour__popover span {
  background-color: #50b482 !important;
}
.reactour__close-button {
  width: 12px !important;
  height: 12px !important;
  color: lightgray;
}

/* Badge styles */
.reactour__badge {
  background-color: #50b482 !important;
  color: white !important;
  border-radius: 50% !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
}

/* Content styles */
.reactour__content {
  padding: 20px !important;
  font-size: 16px !important;
}

/* Navigation styles */
.reactour__navigation {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 20px !important;
}

.reactour__navigation button {
  background-color: #50b482 !important;
  color: white !important;
  border: none !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
}

.reactour__navigation button:disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
}

.reactour__navigation .reactour__dot {
  background-color: #50b482 !important;
}

.reactour__navigation .reactour__dot--active {
  background-color: #50b482 !important;
}

/* Arrow styles */
.reactour__arrow {
  border-color: #50b482 !important;
}

.reactour__arrow--inverted {
  border-color: white !important;
}

.reactour__helper {
  position: fixed !important;
  top: 20% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 1000 !important;
  overflow: visible !important; /* Ensure content isn't clipped */
}

/* Default button styles */
.modal-btn-font {
  font-size: 16px;
  padding: 10px 20px;
}

/* Media query for small screens */
@media (max-width: 576px) {
  .modal-btn-font {
    font-size: 12px;
    padding: 5px 10px;
    width: 30%; /* Set width to 30% */
    margin: 0 auto; /* Center the button */
    display: block; /* Ensure the button is a block element */
  }

  .modal-footer-test {
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping to the next line */
    justify-content: space-between; /* Distributes buttons evenly */
    align-items: center; /* Aligns buttons vertically */
    gap: 0.5rem; /* Adds space between buttons */
  }

  /* Container styling for centering buttons */
  .modal-body .d-flex {
    justify-content: center; /* Center flex items horizontally */
    align-items: center; /* Center flex items vertically */
  }
}

.profile-image {
  height: 35px;
  padding: 6px;
  background-color: rgb(86, 54, 127);
  border-radius: 50%;
}

.reactour__popover button[aria-label^="Go to step"] {
  background: #50b482 !important;
  color: yellow !important;
}

/* .reactour__popover button[aria-label^="Go to step"][style*="background: var(--reactour-accent, #007AFF);"] {
  background: rgb(26, 181, 195) !important;
  border: 1px solid rgb(90, 203, 34) !important;
  color: rgb(145, 22, 73) !important;
} */

.login-page-container {
  min-height: 100vh;
}

.login-form-logo {
  width: 12rem;
  height: auto;
  object-fit: contain;
}

.login-form-container {
  background-color: white;
  border-radius: 30px;
  padding: 25px !important;
  font-family: "Onest", sans-serif;
}

.login-form-body h4 {
  font-size: 2rem;
  font-family: "Onest", sans-serif;
}

.login-form-input label {
  color: gray;
  margin-left: 5px;
}

.login-form-input input,
.otp-form-container input {
  border-radius: 5px;
  padding: 15px 18px;
  background-color: #f0f3f6;
  border: none;
}

.login-form-input input:focus {
  outline: none;
}

.login-form-input input.password {
  border: none;
  background-color: #f0f3f6;
}

input.password:focus {
  box-shadow: none;
  border-right: none;
}

.input-group {
  border-radius: 5px;
  background-color: #f0f3f6;
}

.input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 14px;
}

.login-form-button {
  padding: 15px 25px;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

.login-form-button.back-btn-cv {
  background: gray !important;
  font-weight: 500;
}

.login-form-button.disabled {
  background: gray;
}

.login-page-right {
  position: relative;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 27px;
  overflow: hidden;
}

.login-search-img {
  width: 75%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.polygon-top-left {
  width: 50px;
  height: auto;
  position: absolute;
  top: -1%;
  left: -20px;
}

.cells-top-right {
  height: 100px;
  width: auto;
  position: absolute;
  top: 1%;
  right: 3%;
}

.polygon-bottom-left {
  height: 140px;
  width: auto;
  position: absolute;
  bottom: 0%;
  left: -30px;
}

.polygon-bottom-right {
  height: 180px;
  width: auto;
  position: absolute;
  bottom: 0%;
  right: -60px;
}

.login-stats {
  position: absolute;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1280px) {
  .login-stats {
    width: 100% !important;
  }

  .login-page-container {
    min-height: 90vh !important;
    max-height: 90vh !important;
    align-self: center;
  }

  .signup-right-girl {
    height: 250px !important;
    width: auto;
    transform: translateX(-20px) !important;
  }
}

@media screen and (max-height: 680px) {
  .signup-right-girl {
    height: 200px !important;
    width: auto;
    transform: translateX(0px) !important;
  }
}

.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.password-textfield {
  background-color: #f0f3f0;
  border-radius: 5px;
}

.signup-right-girl {
  position: absolute;
  bottom: 0;
  height: 350px;
  width: auto;
  object-fit: contain;
  left: 30%;
  background-size: contain;
}

.signup-blocks {
  position: absolute;
  transform: translate(-50%, -30%);
  left: 30%;
}

/* otp page styling */

.otp-form-container input {
  border-radius: 14px;
  border: 1px solid #56367f;
  padding: 15px 18px;
}

.otp {
  background-color: rgba(92, 124, 229, 0.15);
  border-radius: 14px;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: bold;
}

.otp:focus {
  background-color: white;
  outline: 1px solid #8a56ce;
  color: #56367f !important;
}

.verify-otp-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: black;
}

.verify-otp-form input:focus {
  color: #8e53db !important;
}

/* In a separate CSS file */
.modal-body-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.modal-body-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-body-scrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.modal-body-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

/* For Firefox */
.modal-body-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

/* admin-report */
.oral-container {
  padding: 45px;
}

@media (max-width: 767px) {
  .oral-container {
    padding: 5px;
  }
}

/* First Checklist */
ul.checklist {
  list-style: none;
  padding: 0;
}

ul.checklist li {
  position: relative;
  padding-left: 30px;
  line-height: 1.5;
}

ul.checklist li:before {
  content: "";
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
  text-align: center;
  padding: 1px;
  margin-top: 2px;
}

ul.checklist li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: white;
  position: absolute;
  left: 0px;
  top: 0;
  font-size: 12px;
}

/* Checklist 2 */
ul.checklist-2 {
  list-style: none;
  padding: 0;
}

ul.checklist-2 li {
  position: relative;
  padding-left: 40px;
  line-height: 1.5;
}

ul.checklist-2 li:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #50b482;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
  text-align: center;
}

ul.checklist-2 li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: white;
  position: absolute;
  left: 4px;
  top: 0;
  font-size: 14px;
}

.report-tabs {
  font-weight: 600;
  font-size: 1.1rem;
  transition: font-size 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .report-tabs {
    font-size: 12px !important;
  }
}

.customWidth .MuiBottomNavigationAction-label {
  min-width: 190px;
}

.report-chart {
  min-height: 300px;
}

.grid-container-voc {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 15px 0;
  text-align: center;
}

@media (max-width: 1000px) {
  .grid-container-voc {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .grid-container-voc {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-item-voc {
  border-radius: 13px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* @media print {
  .page-break-before {
    page-break-before: always;
  }

  .page-break-after {
    page-break-after: always;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }

  .print-container {
    margin: 20px;
    margin-bottom: 30px;
    width: 100%;
  }
} */

.grid-item-voc.card {
  border: 1.18px solid #b7b7b7;
}

.voc-titles {
  font-weight: bold;
}

.col-md-8.offset-md-2 p {
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.oral-pronunciation-qs {
  display: flex;
  color: #4d4d4d;
  justify-content: space-between;
}

.pronunciation-speaking-titles {
  font-weight: 700;
  font-size: 18px;
}

.pronunciation-img {
  width: 50px;
}

.pronunciation-speaking-titles {
  font-weight: 700;
  font-size: 18px;
}

.pronunciation-speaking {
  font-size: 14px;
  color: #787878;
}

.pronunciation-speaking-details {
  font-weight: 700;
  color: #4d4d4d;
}

.table.bg {
  background-color: #f1f1f8;
}

@media (max-width: 1700px) {
  .label-grid {
    width: 30% !important;
  }
}

@media (max-width: 1500px) {
  .label-grid {
    width: 40% !important;
  }
}

@media (max-width: 1176px) {
  .label-grid {
    width: 50% !important;
  }
}

@media (max-width: 994px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 1000px) {
  .label-grid {
    width: 25%;
  }
}

@media (max-width: 873px) {
  .label-grid {
    width: 70% !important;
  }
}

@media (max-width: 787px) {
  .label-grid {
    width: 80% !important;
  }
}

@media (max-width: 722px) {
  .label-grid {
    width: 90% !important;
  }
}

@media (max-width: 671px) {
  .label-grid {
    width: 100% !important;
  }
}

@media (max-width: 631px) {
  .label-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 40% !important;
  }
}

@media (max-width: 537px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 447px) {
  .label-grid {
    width: 70% !important;
  }
}

.label-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  justify-content: start;
  align-items: center;
}

.grid-item {
  color: white;
  border-radius: 20px;
  text-align: center;
  width: auto;
  font-weight: bold;
}

.mistake {
  color: red;
}

.speech-error {
  color: #f0cd08;
}

.suggestion {
  color: #008052;
}

.reactour__close-button {
  color: #50b482; /* Change the close button icon color */
  border-radius: 50%;
  border: 2px solid black;
}
